import { Vue, Prop, Component } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import ProgressBarDash from '@/components/common/progress-bar-dash/ProgressBarDash.vue';
import NetValue from '@/components/common/net-value/netValue.vue';
import { Asset } from '@/store/models/asset';
import { Valuation } from '@/store/models/valuation';

@Component({
  components: {
    WithDownloads,
    FundDocs,
    ProgressBarDash,
    NetValue,
    LazyImage,
  },
})

export default class PropertiesListItem extends Vue {
  @Prop({ default: (): undefined => undefined, required: true }) asset!: Asset;
  @Getter isUserLoggedIn!: boolean;
  @Getter getActiveValuationByAsset!: Function;
  @Getter getAssetValuationSharePrice!: Function;
  @Action openModal!: Function;

  showAllDividends = false;

  get assetValuation(): Valuation | undefined {
    return this.asset && this.getActiveValuationByAsset(this.asset.id);
  }

  get assetSharePrice(): number | undefined {
    return this.asset && this.getAssetValuationSharePrice(this.asset.id);
  }
}
