import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import PropertiesGridFund from '@/components/properties/grid-list/grid/PropertiesGridFund.vue';
import PropertiesListItem from '@/components/properties/list/PropertiesListItem.vue';
import { Asset } from '@/store/models/asset';
import Loader from '@/components/common/loader/Loader.vue';
import { premiumAssetTemplate } from '@/helpers/premium-assets/template';
import PropertyReservationModal from '@/components/properties/grid-list/grid/reservation/PropertyReservationModal.vue';

@Component({
  components: {
    PropertiesGridFund,
    PropertiesListItem,
    Loader,
    PropertyReservationModal,
  },
})

export default class PropertiesGridList extends Vue {
  @Prop({ default: (): Asset[] => [] }) assets!: Asset[];
  @Prop() assetsPromise!: Promise<any>;
  @Prop() viewType!: 'list' | 'grid';

  @Getter isUserLoggedIn!: boolean;
  @Getter getInvestmentByAsset!: any;

  get protectedAssets(): Asset[] {
    return this.assets.map((asset: Asset): Asset => {
      // Use fake data for premium assets if user is not logged in
      if (!this.isUserLoggedIn && asset.premium) {
        return {
          ...asset,
          ...premiumAssetTemplate(this.$i18n.locale),
          premium: asset.premium,
        };
      }

      return asset;
    });
  }
}
