import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ToolbarViewSwitcher extends Vue {
  viewType: 'grid' | 'list' = 'grid'

  setViewType(viewType: 'grid' | 'list'): void {
    this.viewType = viewType;
    this.$emit('input', this.viewType);
  }
}
