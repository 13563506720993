import { Vue, Component } from 'vue-property-decorator';

const sortOptions = ['date'] as const;
export type SortOptions = typeof sortOptions[number];
export type Order = 'DESC' | 'ASC';
export type SortingChangeEmit = { order: Order, typeOfSort: SortOptions };

@Component({})
export default class Sorting extends Vue {
  typeOfSort: SortOptions = 'date';
  order: Order = 'DESC';
  sortOptions: { [Key in SortOptions]: string } = {
    date: this.$t('common.date') as string,
  };

  handleClick(newOrder: Order, newSortType?: SortOptions): void {
    // Update component data so the styling and content can react
    if (newSortType) {
      this.typeOfSort = newSortType;
    }
    this.order = newOrder;
    // Emit new ordering
    const emitObj: SortingChangeEmit = { order: this.order, typeOfSort: this.typeOfSort };
    this.$emit('change-sort', emitObj);
  }
}
