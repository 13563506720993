import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';

export interface Country {
  name: string;
  isOpen?: boolean;
  isSelected?: boolean;
  regions?: Country[];
}

@Component({})
export default class LocationFilter extends Vue {
  @Prop({ default: false }) enableRegions!: boolean;
  @Prop({ default: (): Country[] => [] }) locations!: Country[];

  countries: Country[] = []

  sortBy = sortBy
  searchQuery = ''

  dropdownOpen = false
  regionPanelOpen = false

  @Watch('selectedCountries', { deep: true })
  onSelectedCountriesChange(newSelectedCountries: Country[]): void {
    const countries = newSelectedCountries.map(({ name }): string => name);
    const regions = this.selectedRegions.map(({ name }): string => name);

    this.$emit('change', this.enableRegions ? { countries, regions } : countries);
  }

  @Watch('locations', { immediate: true })
  onDataChange(newData: Country[]): void {
    this.countries = newData.map((country): Country => {
      if (this.enableRegions && country.regions) {
        return { ...country, regions: country.regions.map((region): Country => ({ ...region, isSelected: false })) };
      }

      return { ...country, isSelected: true };
    });
  }

  get items(): Country[] | null {
    if (this.searchQuery) {
      return this.countries.filter((country): boolean => {
        if (this.enableRegions && country.regions) {
          return !!country.name.match(this.queryString) || country.regions.some((region): boolean => !!region.name.match(this.queryString));
        }

        return !!country.name.match(this.queryString);
      });
    }

    return this.countries;
  }

  get queryString(): RegExp {
    return new RegExp(this.searchQuery, 'ig');
  }

  openRegions(country: Country): void {
    country.isOpen = !country.isOpen;
    this.regionPanelOpen = !this.regionPanelOpen;
  }

  hasSelectedRegions(country: Country): boolean | undefined {
    return country.regions?.some(({ isSelected }): boolean => isSelected === true);
  }

  toggleAllCountries(): void {
    const isSelected = !this.countries.every((country): boolean | undefined => {
      if (this.enableRegions && country.regions) {
        return country.regions.every(({ isSelected }): boolean => isSelected === true);
      }

      return country.isSelected;
    });

    this.setAllCountries(isSelected);
  }

  setAllCountries(isSelected: boolean): void {
    this.countries = this.countries.map((country): Country => {
      if (this.enableRegions && country.regions) {
        this.setAllRegions(country, isSelected);
        return country;
      }

      return { ...country, isSelected };
    });
  }

  toggleAllRegions(country: Country): void {
    const isSelected = !country.regions?.every(({ isSelected }): boolean => isSelected === true);

    if (this.enableRegions && country.regions) {
      this.setAllRegions(country, isSelected);
    }
  }

  setAllRegions(country: Country, isSelected: boolean): void {
    country.regions = country.regions!.map((region): Country => ({ ...region, isSelected }));
  }

  selectCountryOrRegion(countryOrRegion: Country): void {
    if (this.enableRegions && countryOrRegion.regions) {
      this.setAllRegions(countryOrRegion, !countryOrRegion.isSelected);
    }

    countryOrRegion.isSelected = !countryOrRegion.isSelected;
  }

  get selectedCountries(): Country[] {
    return this.countries.filter((country): boolean | undefined => {
      if (this.enableRegions && country.regions) {
        return this.allRegionsSelected(country);
      }

      return country.isSelected;
    });
  }

  get selectedCountryNames(): string {
    return this.selectedCountries.map(({ name }): string => name).join(' ,');
  }

  get amountSelectedCountries(): number {
    return this.selectedCountries.length;
  }

  get allSelected(): boolean {
    return this.amountSelectedCountries === this.locations.length;
  }

  get noneSelected(): boolean {
    return this.amountSelectedCountries === 0;
  }

  get selectedItemsLabel(): string {
    return this.allSelected ?
      'All countries' :
      this.noneSelected ? '0 countries' : this.selectedCountryNames +
      this.enableRegions && this.amountSelectedRegions ? `, ${this.amountSelectedRegions} regions` : '';
  }

  allRegionsSelected(country: Country): boolean | undefined {
    return country.regions?.every(({ isSelected }): boolean => isSelected === true);
  }

  get selectedRegions(): Country[] {
    return flatten(
      this.countries
        .filter(({ regions }): boolean => !!regions)
        .map(({ regions }): Country[] => regions as Country[]),
    )
      .filter(({ isSelected }): boolean => !!isSelected);
  }

  get amountSelectedRegions(): number {
    return this.selectedRegions.length;
  }
}
